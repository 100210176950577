const tlgAnchorSel = '.tlg-anchor';
document.addEventListener('click', function (e) {
	const $element = e.target.closest(tlgAnchorSel);
	if (!$element) return;

	const $otherElements = document.querySelectorAll(tlgAnchorSel);
	for (let $el of $otherElements) {
		$el.classList.toggle("tlg-anchor-active", $el === $element);
	}

	$element.parentElement.scrollTo({
		left: $element.offsetLeft,
	});
});